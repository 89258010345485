import React, { useState } from "react"
import "./ApprovalNavbar.css"
import DirectorApprovalBill from "../DirectorApprovalBill/DirectorApprovalBill";
import { Button } from "@mui/material";
import DirectorbillApproved from "../DirectorbillApproved/DirectorbillApproved";

const NewApprovals = ({ user }) => {
  const [selectedComponent, setSelectedComponent] = React.useState(<DirectorApprovalBill user={user} />);

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  }; 
  return (
    <>
      <div className="outflownavbar-container">
        <Button
          style={{ textDecoration: "none" }} sx={{ fontSize: "0.6rem", color: selectedComponent?.type?.name === "DirectorApprovalBill" ? "white" : "#146EF5" }} size="small" variant={selectedComponent?.type?.name === "DirectorApprovalBill" ? "contained" : null}
          onClick={() => handleComponentChange(<DirectorApprovalBill user={user} />)}
        >
          Outflow For Approvals
        </Button>
        <Button
          style={{ textDecoration: "none" }} sx={{ fontSize: "0.6rem", color: selectedComponent?.type?.name === "DirectorbillApproved" ? "white" : "#146EF5" }} size="small" variant={selectedComponent?.type?.name === "DirectorbillApproved" ? "contained" : null}
          onClick={() => handleComponentChange(<DirectorbillApproved user={user} />)}
        >
          Approved
        </Button>
      </div>
      {selectedComponent}
    </>
  );
}

export default NewApprovals;