import React, { useEffect, useState } from "react";
import { getUserTasks } from "../../services/Currency.service";
import "./TimesheetReports.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const TimesheetReports = ({ user }) => {
  const [tasks, setTasks] = useState([]);

  // console.log("task state", tasks)

  const fetchTasks = async () => {
    try {
      const data = await getUserTasks(user?.id);
      // console.log("Fetched tasks data:", data); // Debugging statement
      setTasks(data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchTasks();
    }
  }, [user]);

  return (
    <div className="reports-container">
      {/* <div className="reports-navbar">Navbar for Filter</div> */}
      <TableContainer component={Paper} sx={{paddingTop:"8px"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Day</TableCell>
              <TableCell>Hour</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.length > 0 ? (
              tasks.map((task, taskIdx) => (
                <TableRow key={`${taskIdx}`}>
                  <TableCell>{task.title ? task.title : "no data"}</TableCell>
                  <TableCell>{task.Project}</TableCell>
                  <TableCell>{task.day}</TableCell>
                  <TableCell>{task.hour ? task.hour : "no data"}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TimesheetReports;
