import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LoadingButton from '@mui/lab/LoadingButton';

const DeleteModal = ({ openDeleteModal, handleClosePopUp, handleDelete, isDeleteSubmit }) => {
    return (
        <>
            <Dialog
                open={openDeleteModal}
                onClose={handleClosePopUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to delete the user ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ fontSize: "0.6rem" }} variant='outlined' onClick={() => handleClosePopUp()}>Cancel</Button>
                    <LoadingButton
                        sx={{ fontSize: "0.6rem" }} variant='contained'
                        onClick={handleDelete}
                        loading={isDeleteSubmit}
                        loadingPosition="end"
                    >
                        <span>Delete</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteModal
