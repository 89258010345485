import React from 'react'
import './UserLogin.css'
import refresh from '../../assest/Refresh_Logo_Large (2).jpg'
import zones from '../../assest/Zones Logo-930x310(1) (2).jpg'

const Tab = ({ companyName, src }) => {
    return (
        <div className='users-login-tabs'>
            <div className='user-login-tab'>
                <div className=''>
                    <img src={src} style={{ width: '120px' }} alt='logo' />
                </div>
                <h1 style={{ fontSize: '1rem' }}>{`Welcome to CENTRAL ${companyName}`}</h1>
                <p>Franchise financial management </p>
                <button className='redirect-btn'>Redirect</button>
            </div>
        </div>
    )
}

const UserLogin = () => {
    return (
        <>
            <div className="Login-container">
                <div className='users-login-tabs'>
                    <div className='user-login-tab'>
                        <div className=''>
                            <img src={refresh} style={{ width: "120px" }} alt='logo' />
                        </div>
                        <h1 style={{ fontSize: "1rem" }}>Welcome to CENTRAL Refresh</h1>
                        <p>Franchise financial management</p>
                        <button className='redirect-btn'>Redirect</button>
                    </div>
                </div>
                <div className='users-login-tabs'>
                    <div className='user-login-tab'>
                        <div className=''>
                            <img src={zones} style={{ width: "120px" }} alt='logo' />
                        </div>
                        <h1 style={{ fontSize: "1rem" }}>Welcome to CENTRAL Zones</h1>
                        <p>Franchise financial management</p>
                        <button className='redirect-btn'>Redirect</button>
                    </div>
                </div>
                <div className='users-login-tabs'>
                    <div className='user-login-tab'>
                        <div className=''>
                            <img src={refresh} style={{ width: "120px" }} alt='logo' />
                        </div>
                        <h1 style={{ fontSize: "1rem" }}>Welcome to CENTRAL OnCore</h1>
                        <p>Franchise financial management</p>
                        <button className='redirect-btn'>Redirect</button>
                    </div>
                </div>
                <div className='users-login-tabs'>
                    <div className='user-login-tab'>
                        <div className=''>
                            <img src={refresh} style={{ width: "120px" }} alt='logo' />
                        </div>
                        <h1 style={{ fontSize: "1rem" }}>Welcome to CENTRAL We Short It</h1>
                        <p>Franchise financial management</p>
                        <button className='redirect-btn'>Redirect</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserLogin
