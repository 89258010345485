import React from "react";
import "./Navbar.css";
import { signOut } from "../../services/api.service";
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from "@mui/material";

const Navbar = ({ user }) => {
  // console.log(user, 'user')
  const handleLogout = async () => {
    return signOut();
  };

  return (
    <>
      <div className="navbar-main-div">
      </div>
      <div>
        <div className="main-div">
          <div className="left-div">CENTRAL</div>
          <div className="right-div">
            <div className="inner-right-div">
              <div className="top-div">
                <span style={{ fontSize: "0.8rem", fontWeight: "600" }}>{user?.name}</span> <br />
                <span style={{ fontSize: "0.8rem", fontWeight: "400" }}>{user?.email}</span>
              </div>
              <img
                src={user?.picture}
                // src="https://easydrawingguides.com/wp-content/uploads/2023/03/how-to-draw-tanjiro-kamado-from-demon-slayer-featured-image-1200-1001x1024.png"
                alt="Admin/director"
              />
            </div>
            <div className="inner-left-div">
              <IconButton
                onClick={handleLogout}
              >
                <LogoutIcon color="success" />
              </IconButton>
            </div>
          </div>
        </div>
        {/* <div style={{ padding: "0 1rem" }}>
        <hr />
      </div> */}
      </div>
    </>
  );
};

export default Navbar;
