import React, { useState, useContext, useEffect } from "react";
import { getMonth, getWeek, getWeekDay } from "../util/util";
import CalenderHeader from "../TimesheetComponents/CalenderHeader";
import CalenderNavbar from "../TimesheetComponents/CalenderNavbar";
import Month from "../TimesheetComponents/Month";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import GlobalContext from "../TimesheetContext/GlobalContext";
import { Events } from "../TimesheetComponents/Events";
import "./Timesheet.css";
import dayjs from "dayjs";
import TimesheetNavbar from "../TimesheetNavbar/TimesheetNavbar";

const Timesheet = ({ user }) => {
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const [currentWeek, setCurrentWeek] = useState(getWeek(dayjs()));
  const { monthIndex, setShowEvent, showEvent, dayIndex, openTimeSheetModal } =
    useContext(GlobalContext);

  useEffect(() => {
    setCurrentWeek(getWeek(dayIndex));
  }, [dayIndex]);

  return (
    <div>
      {openTimeSheetModal && <Events user={user} />}
      <Grid container direction="column">
        <CalenderHeader user={user} />
        <Month currentWeek={currentWeek} user={user} />
      </Grid>
    </div>
  );
};

export default Timesheet;
