import React, { useState, useEffect } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { GetAllUser, deleteEmp } from "../../../../services/api.service";
import "./Table.css";
// import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "../Common/Modal";
import SnackbarMsg from "../Common/Snackbar";
import Spinner from "../../../Spinner/Spinner";
import { IconStore } from "../Common/IconStore";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LoadingButton from '@mui/lab/LoadingButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip } from '@mui/material';
const TableData = ({ setGetAllUser }) => {
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [updateData, setUpdateData] = useState({});
  const [openSnak, setOpenSnak] = useState(false);
  const [apiMsg, setApiMsg] = useState("Found Error While Network Call");
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteItemId, setDeleteItemId] = React.useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  useEffect(() => {
    getAllUser();

    setGetAllUser(() => getAllUser);
  }, [page]);

  const getAllUser = async () => {
    let obj = {
      page: page,
      size: size,
    };
    const userData = await GetAllUser(obj);
    setUserList(userData.rows);

    setTotalPages(userData.totalPages);
  };
  // useEffect(() => {
  //   getAllUser();

  //   setGetAllUser(() => getAllUser);
  // }, [userList]);
  const handleArrow = (direction) => {
    setPageCount((prevPageCount) => {
      const nextPage =
        direction === "right"
          ? prevPageCount + 1
          : direction === "left" && prevPageCount > 1
            ? prevPageCount - 1
            : prevPageCount;
      setPage(nextPage - 1);
      return nextPage;
    });
  };

  const updateUser = (type, id) => {
    setDialogData(type);
    setOpen(true);
    setUpdateData(userList?.find((elem, i) => elem.id == id));
  };


  const deleteItem = async (id) => {
    setIsDeleteLoading(true)
    const result = await deleteEmp(id);
    return result?.success
      ? (getAllUser(),
        setOpenSnak(true),
        setApiMsg(result?.message),
        setIsLoading(false),
        setTimeout(onClose, 2000),
        setOpenDeleteModal(false),
        setIsDeleteLoading(false)
      )
      : console.log("NOT found");
  };

  const onClose = () => {
    setOpen(false);
    setOpenSnak(false);
  };

  const handleClickOpen = (id) => {
    setOpenDeleteModal(true);
    setDeleteItemId(id)
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer component={Paper} className="superadmin">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Hourly Rate</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Role</TableCell>
                <TableCell align="left">Organisation</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                userList?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell scope="row">
                      {row?.first_name + " " + row?.last_name || "---"}
                    </TableCell>
                    <TableCell scope="row">
                      {row?.hourly_rate || "0"}
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: '8rem' }}>{row?.email_id || "---"}</TableCell>
                    <TableCell align="left">
                      {
                        row.user_role_array.map((role, index) => (
                          <React.Fragment key={role.role_id}>
                            {role.roleName || '---'}
                            {index < row.user_role_array.length - 1 && ', '}
                          </React.Fragment>
                        ))
                      }
                    </TableCell>
                    <TableCell align="left">
                      {row.user_role_array.map((role, index) => (
                        <React.Fragment key={role.role_id}>
                        <b>{role.roleName} : </b>
                          {role.org_array.length > 3 ? (
                            <Tooltip title={role.org_array.map((org) => org.orgName).join(', ')} placement="top">
                              <span style={{ cursor: 'pointer' }}>
                                {role.org_array.slice(0, 3).map((org, orgIndex) => (
                                  <React.Fragment key={org.id}>
                                    {org.orgName || '---'}
                                    {orgIndex < 2 && ', '}
                                  </React.Fragment>
                                ))}
                                {'...more'}
                              </span>
                            </Tooltip>
                          ) : (
                            role.org_array.map((org, orgIndex) => (
                              <React.Fragment key={org.organisationId}>
                                {org.orgName || '---'}
                                {orgIndex < role.org_array.length - 1 && ', '}
                              </React.Fragment>
                            ))
                          )}
                          {index < row.user_role_array.length - 1 ? <br /> : ''}
                        </React.Fragment>
                      ))}
                    </TableCell>

                    <TableCell align="left">
                      <IconButton
                        onClick={() => updateUser("employee", row?.id)}
                      >
                        <IconStore.edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleClickOpen(row?.id)} /* onClick={() => deleteItem(row.id)} */>
                        <IconStore.delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <div className="d-flex py-20 fr">
            <span>Page {pageCount + " " + "of" + " " + totalPages}</span>
            <IconButton
              onClick={() => handleArrow("left")}
              disabled={pageCount == 1}
            >
              <ArrowBackIosNewIcon color="grey" style={{ fontSize: "20px" }} />
            </IconButton>
            <IconButton
              onClick={() => handleArrow("right")}
              disabled={pageCount === totalPages}
            >
              <ArrowForwardIosIcon color="grey" style={{ fontSize: "20px" }} />
            </IconButton>
          </div>
        </TableContainer>
      )}

      <Modal
        open={open}
        onClose={onClose}
        data={dialogData}
        isUpdate={true}
        updateData={updateData}
        getAllUser={getAllUser}
      />
      {/* delete confirm popup */}
      <Dialog
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete the user ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontSize: "0.6rem" }} variant='outlined' onClick={handleClose}>Cancel</Button>
          <LoadingButton
            sx={{ fontSize: "0.6rem" }} variant='contained'
            onClick={() => deleteItem(deleteItemId)}
            loading={isDeleteLoading}
            loadingPosition="end"
          >
            <span>Delete</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* delete confirm popup */}
      <SnackbarMsg apiMsg={apiMsg} open={openSnak} color="green" />
    </>
  );
};

export default TableData;
