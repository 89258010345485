import React from "react";

const GlobalContext = React.createContext({
  monthIndex: 0,
  setMonthIndex: (index) => {},
  dayIndex: 0,
  setDayIndex: (index) => {},
  daySelected: null,
  setDaySelected: (day) => {},
  showEvent: false,
  setShowEvent: () => {},
  dispatchCalEvent: ({ type, payload }) => {},
  savedEvents: [],
  setSavedEvents: () => {},
  selectedEvent: null,
  setSelectedEvent: () => {},
});

export default GlobalContext;
