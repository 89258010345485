

const getClientHost = () => {
  if (window.location.hostname === 'localhost') {
    return `http://${window.location.hostname}:3000`
  }
  return `https://${window.location.hostname}`
}

const getServerHost = () => {
  if (window.location.hostname === 'localhost') {
    return `http://${window.location.hostname}:4500`
  }
  return `https://${window.location.hostname}`
}

module.exports = {
  getClientHost,
  getServerHost,
}