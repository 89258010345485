import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { GetUsers } from "../../services/Currency.service";

const ProjectStaff = ({ addProject, setaddProject }) => {
  const [staff, setstaff] = useState([]);
  const [employee, setEmployees] = useState([]);

  /// getting user's mapped organisation id array
  const userData = sessionStorage.getItem("user")
  const userObject = JSON.parse(userData);
  // console.log("userObject", userObject)

  const addStaff = (e) => {
    e.preventDefault();
    const newAssignedStaff = {
      staff_name: "",
      emp_id: ""
    };
    setstaff([...staff, newAssignedStaff]);
  };

  const handleChange = (idx, event) => {
    const { name, value } = event.target;
    const id = event.target.options[event.target.selectedIndex].id;
    // console.log("new-staff", value, id);
    const updatedStaff = staff.map((stf, index) =>
      idx === index ? { ...stf, [name]: value, emp_id: id } : stf
    );

    setstaff(updatedStaff);
    // console.log(updatedStaff, 'staff')
    setaddProject({ ...addProject, project_staff: updatedStaff });
  };

  const fetchEmp = async () => {
    // const data = await getAllEmp(3);
    const data = await GetUsers()
    data?.length > 0 ? setEmployees(data) : console.log("NOT DATA FOUND");
  }

  useEffect(() => {
    fetchEmp();
  }, []);

  // console.log("employee", employee)

  // const filterEmp = employee.filter((item) => item.organisations?.some((org) => userObject?.includes(org.id)))
  const filterEmp = employee.filter((item) => item.organisations?.some((org) => userObject?.orgs?.includes(org.id)));
  // console.log("filterEmp", filterEmp)

  return (
    <div className="project-staff">
      <div>
        {staff.map((element, index) => (
          <div key={index} style={{ margin: "5px" }}>
            <span>
              <select
                type="text"
                name="staff_name" style={{ width: "100%" }}
                value={element.staff_name}
                onChange={(event) => handleChange(index, event)}
              >
                <option value="" disabled selected>
                  Select Staff
                </option>
                {filterEmp.map((elem) => {
                  return (
                    <option key={elem.id} value={elem.last} id={elem.id}>{elem.first_name}</option>
                  );
                })}
              </select>
            </span>
          </div>
        ))}
      </div>
      <div>
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={addStaff}
          style={{
            color: "#0056b3",
            height: "15px",
            // margin: "15px",
            // display: "flex",
            justifyContent: "flex-start",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

export default ProjectStaff;
