import React, { useEffect, useState } from "react";
import { fetchApi } from "../../services/api.service";
import "./Project.css";
import { getProject } from "../../services/Currency.service";
import ProjectNavbar from "../ProjectNavbar/ProjectNavbar";
import { Button, Card, Dialog, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import ProjectForm from "../ProjectForm/ProjectForm";
import Spinner from "../../components/Spinner/Spinner";

function AddProjectFormDialog(props) {
  // console.log("PROPS", props);
  const { onClose, open } = props;
  // console.log("open", open);

  const handleClose = () => {
    onClose(open);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={"md"}>
      <DialogTitle>Add Project</DialogTitle>
      <ProjectForm handleClose={handleClose} user={props?.user?.id} />
    </Dialog>
  );
}
AddProjectFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

const Project = ({ user }) => {
  const [orgs, setOrgs] = useState([]);
  const [showProject, setShowProject] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [openFormModal, setOpenFormModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const handleClickOpen = () => {
    setOpenFormModal(true);
  };

  const handleClose = () => {
    setOpenFormModal(false);
  };

  // const handleChange = (e) => {
  //   const OrgName = e.target.value;
  //   const [activeProjectOrg] = orgs.filter((ele, i) => ele.name === OrgName);
  //   const { abbr } = activeProjectOrg;
  //   console.log(abbr);
  //   setOrgId(abbr);
  // };

  // const getOrg = async () => {
  //   const urlOrg = await fetchApi("/api/orgs");
  //   const orglist = await urlOrg.json();
  //   console.log(orglist);
  //   setOrgs(orglist.palyload);
  // };

  // useEffect(() => {
  //   getOrg();
  // }, []);

  const getNewProjects = async () => {
    setIsLoading(true)
    let newProject = await getProject(user.id, user.role);
    if (newProject) {
      setShowProject(newProject);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getNewProjects();

  }, [openFormModal]);

  return (
    <div className="activeProjects-mainDiv">
      <div className="project-container">
        <div className="business-unit-div">
          <Button
            size="small"
            variant="contained"
            sx={{ fontSize: "0.6rem" }}
            onClick={handleClickOpen}
          >
            Add New Projects
          </Button>
        </div>
        <div>
          <AddProjectFormDialog
            open={openFormModal}
            onClose={handleClose}
            user={user}
          />
        </div>
        <div>
          <div className="project-nav">
            <ProjectNavbar />
          </div>
          {isLoading ? false : (
            
            showProject?.map((elem, idx) => {
              console.log("new project", elem);
              const backgroundColor = idx % 2 !== 0 ? '#F8F8F8' : 'white'
              return <Card key={idx} className="project-inner-container" style={{ backgroundColor }}>
                <div className="des-cost">
                  <div className="des-cost-1">{elem.project_title}</div>
                  <div className="des-cost-2">Cost$: {elem.cost}</div>
                </div>
                <div className="description">
                  Description :{" "}
                  <span className="span">{elem.description}</span>
                </div>
                <div className="dates-staff-split">
                  <div className="category">
                    <p className="p">
                      Category:<span className="span">{elem.category}</span>
                    </p>
                    <p className="p">
                      Gl-Code:<span className="span">{elem.gl_code}</span>
                    </p>
                    <p className="p">
                      Start Date:
                      <span className="span">{elem.start_date}</span>
                    </p>
                    <p className="p">
                      End Date:<span className="span">{elem.end_date}</span>
                    </p>
                  </div>
                  <div className="category">
                    <p className="p">Assigned Staff</p>
                    {elem.project_staffs.map((e, i) => {
                      return (
                        <p key={i} className="p" style={{ color: "black" }}>
                          {e.staff_name}
                        </p>
                      );
                    })}
                  </div>
                  <div className="category">
                    <p className="p">Assigned Splits</p>
                    {elem.project_splits.map((e, i) => {
                      return (
                        <p key={i} className="p">
                          {e.company_abbr}:
                          <span className="span">{e.project_per}%</span>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </Card>
            })
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Project;

{
  /* {showProject.map((elem, idx) => {
          return orgId === elem.project_org_id && elem.is_Archived === false ? (
            <div key={idx} className="project-inner-container">
              <div className="des-cost">
                <div className="des-cost-1">{elem.project_title}</div>
                <div className="des-cost-2">Cost$:{elem.cost}</div>
              </div>
              <div className="description">
                Description : <span className="span">{elem.description}</span>
              </div>
              <div className="dates-staff-split">
                <div className="category">
                  <p className="p">
                    Category:<span className="span">{elem.category}</span>
                  </p>
                  <p className="p">
                    Gl-Code:<span className="span">{elem.gl_code}</span>
                  </p>
                  <p className="p">
                    Start Date:<span className="span">{elem.start_date}</span>
                  </p>
                  <p className="p">
                    End Date:<span className="span">{elem.end_date}</span>
                  </p>
                </div>
                <div className="category">
                  <p className="p">Assigned Staff</p>
                </div>
                <div className="category">
                  <p className="p">Assigned Splits</p>
                  {elem.project_splits.map((e, i) => {
                    return (
                      <p key={i} className="p">
                        {e.company_abbr}:
                        <span className="span">{e.project_per}%</span>
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null;
        })} */
}
