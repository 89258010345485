import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import Content from "./components/Content/Content";
import { useEffect, useState } from "react";
import Login from "./components/Login/Login";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Currency from "./components/Currency/Currency";
import OutflowBill from "./pages/OutflowBill/OutflowBill";
import OutflowForm from "./pages/OutflowForm/OutflowForm";
import OutflowApprovalbill from "./pages/OutflowApprovalbill/OutflowApprovalbill";
import DirectorApprovalBill from "./pages/DirectorApprovalBill/DirectorApprovalBill";
import { getServerHost } from "./services/url.service";
import ReadytosendtoXERO from "./pages/ReadytosendtoXERO/ReadytosendtoXERO";
import DirectorbillApproved from "./pages/DirectorbillApproved/DirectorbillApproved";
import { fetchApi } from "./services/api.service";
import RefusalPage from "./pages/RefusalPage/RefusalPage";
import RefusedInvoices from "./pages/RefusedInvoices/RefusedInvoices";
import { SenttoXero } from "./pages/SenttoXero/SenttoXero";
import DirectorPayableInvoices from "./pages/DirectorPayableInvoices/DirectorPayableInvoices";
import DirectorRefusedInvoices from "./pages/DirectorRefusedInvoices/DirectorRefusedInvoices";

import Xero from "./components/Xero/Xero";
import { DirectorReceivableInvoices } from "./pages/DirectorReceivableInvoices/DirectorReceivableInvoices";
import SendtoxeroPayInv from "./pages/SendtoxeroPayInv/SendtoxeroPayInv";
import Project from "./pages/Project/Project";
import ProjectForm from "./pages/ProjectForm/ProjectForm";
import Timesheet from "./pages/Timesheet/Timesheet";
import TimesheetReports from "./pages/TimesheetReports/TimesheetReports";
import DirectorsView from "./pages/DirectorsView/DirectorsView";
import { NewXero } from "./pages/NewXero/NewXero";
import { NewApprovals } from "./pages/NewApprovals/NewApprovals";
import { DashboardNew } from "./pages/DashboardNew/DahsboardNew";
import Superadmin from "./components/superadmin-home/Superadmin"

import ProjectStaff from "./pages/ProjectStaff/ProjectStaff";
import TimesheetNavbar from "./pages/TimesheetNavbar/TimesheetNavbar";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import UserLogin from "./components/UserLogin/UserLogin";
import BiReports from "./pages/BiReports/BiReports";

/**
 * Routes of this children require authentication.
 *
 * @param {Object} props
 * @param {boolean} [props.auth]
 */
const GuardedRoutes = ({ auth, user }) => {
  // console.log('guard', auth, user)
  // Still authenticating; Return optional loading indicator here to display the loading state.
  if (auth && user && user.role == 1) return <Superadmin user={user} />;
  if (auth === undefined) return null;
  // If true, continue with router outlet; If false, navigate to login.
  return auth ? <Outlet /> : <Navigate to="/" replace />;
};

function App() {
  // const [isClicked, setIsClicked] = useState(false);
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState();
  const reactLocation = useLocation();

  // Initial authenticate; It is called upon every React router location change.
  // If valid, store token and allow routing to guarded routes; Additionally store the user details in localStorage.
  // If invalid, redirect to login, clear user details from the localStorage.
  useEffect(() => {
    // Do not authenticate for the sign-in location.
    if (reactLocation.pathname === "/") return;

    // Make an authentication request to the API server.
    fetchApi("/api/auth/authenticate")
      .then((result) => {
        // console.log("result", result)
        return result?.ok ? result.json() : null
      })
      .then((token) => {
        // console.log(token)

        // Set auth state.
        setAuth(!!token);
        // Store user data in sessionStorage if it was returned.
        if (token) {
          sessionStorage.setItem("user", JSON.stringify(token));
          // console.log(token)
          setUser(token);
        }
      });
  }, [reactLocation]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route element={<GuardedRoutes auth={auth} user={user} />}>
        {user?.role === 1 && <Route path="/superadmin" element={<Superadmin user={user} />} />}
        <Route path="/users" element={<UserLogin />} />

        <Route path="/home" element={<Home user={user} />}>
          {user?.role === 2 && <Route path="currency" element={<Currency />} />}
          <Route path="bi-reports" element={<BiReports />} />
          {user?.role === 2 && <Route path="content" element={<Content user={user} />} />}
          {user?.role !== 1 && <Route path="timesheet" element={<TimesheetNavbar user={user} />} />}
          {(user?.role === 2 || user?.role === 4) && <Route path="activeprojects" element={<Project user={user} />} />}
          {((user?.role === 2 || user?.role === 4) && user?.isSuperAdmin) && <Route path="superadmin" element={<Superadmin user={user} />} />}
        </Route>
      </Route>
      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
  );
}

export default App;
