import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import Project from "../Project/Project";

const ProjectNavbar = ({user}) => {
  const [selectedComponent, setSelectedComponent] = React.useState(<Project user={user}/>);

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };
  return (
    <div>
     
        <h3
          style={{ textDecoration: "none" }} sx={{ fontSize: "0.6rem", color: selectedComponent?.type?.name === "Project" ? "white" : "#146EF5" }} size="small" variant={selectedComponent?.type?.name === "Project" ? "contained" : null}
          onClick={() => handleComponentChange(<Project user={user}/>)}
        >
         Active Projects List
        </h3>
        {/* <Button
          style={{ textDecoration: "none" }} sx={{ fontSize: "0.6rem", color: selectedComponent?.type?.name === "DirectorApprovalBill" ? "white" : "#146EF5" }} size="small" variant={selectedComponent?.type?.name === "DirectorApprovalBill" ? "contained" : null}
          // onClick={() => handleComponentChange(<DirectorApprovalBill />)}
        >
          Archived Projects
        </Button> */}
      
    </div>
  );
};

export default ProjectNavbar;
