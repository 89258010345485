import React, { useEffect, useState } from "react";
import "../Employee/AddEmployee.css";
import Grid from "@mui/material/Grid";
import {
  AddUser,
  GetAllOrg,
  updateUser,
} from "../../../../services/api.service";
import SnackbarMsg from "../Common/Snackbar";
import LoadingButton from "@mui/lab/LoadingButton";
import { handleNumberFormat, isValidEmail } from "../utils/function";

const AddEmployee = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { onClose, isUpdate, updateData, getAllUser } = props;
  const radioArray = [
    { name: "Director", value: "director", id: 2 },
    { name: "Employee", value: "employee", id: 3 },
    { name: "Manager", value: "manager", id: 4 },
  ];

  const [OrgName, setOrgName] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [inValidEmail, setInValidEmail] = useState(false)
  const [open, setOpen] = useState(false);
  const [apiMsg, setAPIMsg] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");
  const [orgNameLoad, setOrgNameLoad] = useState(false);
  const [roles, setRoles] = useState([

  ]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    hourly_rate: 0,
  });

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isUpdate && updateData && orgNameLoad) {
      prefillForm(updateData);
    }
  }, [isUpdate, orgNameLoad]);

  useEffect(() => {
    getAllOrg();
  }, []);
  const getAllOrg = async () => {
    try {
      let orgs = await GetAllOrg();
      orgs = orgs.map(org => ({
        ...org,
        checked: false,
        roles: []
      }));

      setOrgName(orgs);
      setOrgNameLoad(true);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'email_id') {
      if (!isValidEmail(value)) {
        setInValidEmail(true)
      }
      else {
        setInValidEmail(false)
      }
    }
  };
  const prefillForm = (data) => {
    setFormData({
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email_id: data.email_id || "",
      hourly_rate: data.hourly_rate || '',
    });

    const FillCheckbox = [];

    data?.user_role_array.forEach((x) => {
      const orgIds = x.org_array.map(org => org.id);
      const roleObj = {
        role_id: x.role_id,
        org_array: orgIds
      };
      FillCheckbox.push(roleObj);
    });

    setRoles(FillCheckbox || []);
    const selectedRoles = FillCheckbox?.map(role =>
      radioArray.find(r => r.id === role.role_id)?.value
    ).filter(Boolean);
    setSelectedPosition(selectedRoles);

    const selectedOrgIds = FillCheckbox?.flatMap(role => role.org_array);
    setOrgName((prevOrgNames) =>
      prevOrgNames.map((org) => ({
        ...org,
        checked: selectedOrgIds.includes(org.id),
      }))
    )
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // setIsLoading(true)
    const selectedOrgNames = OrgName.filter((org) => org.checked).map(
      (org) => org.id
    );
    const selectOrgName = OrgName.filter((org) => org.checked)
    let roleResult = roles.filter((x) => x.role_id).some((role) => role.org_array.length == 0)

    if (roleResult) {
      setAPIMsg("Select organization")
      setSnackbarColor("red")
      setOpen(true)
      setTimeout(() => { setOpen(false) }, [1500])
      return;
    }
    const requiredFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email_id: "Email",
      hourly_rate: "Hourly Rate"
    };

    const missingFields = Object.keys(requiredFields).filter(field => !formData[field]);

    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map(field => requiredFields[field]);
      setAPIMsg(`Please fill in the following fields: ${missingFieldNames.join(', ')}`)
      setSnackbarColor("red")
      setOpen(true)
      setTimeout(() => { setOpen(false) }, [1500])
      // alert(`Please fill in the following fields: ${missingFieldNames.join(', ')}`);
      return;
    }
    if (roles?.length == 0) {
      setAPIMsg("Select any one role")
      setSnackbarColor("red")
      setOpen(true)
      setTimeout(() => { setOpen(false) }, [1500])
      // alert("Select any one role");
      return;
    }
    const data = {
      ...formData,
      user_role_array: roles
      // org_array: selectOrgName,
    };
    setIsLoading(true)
    const submitAction = isUpdate ? updateUser : AddUser;
    submitAction({ ...data, ...(isUpdate && { userId: updateData?.id }) }).then(
      (res) => {
        if (res) {
          setAPIMsg(res?.message);
          setOpen(true);
          getAllUser();
          setIsLoading(false)
          res?.success ? setSnackbarColor("green") : setSnackbarColor("red");
        }
        setTimeout(handleClose, 800);
      }
    );
  };

  const handleRadioChange = (event) => {
    const { checked, value, id } = event.target;
    let ID = Number(id)
    setSelectedPosition((prevSelectedPosition) => {
      let newArr = [];
      if (checked) {
        newArr = [...prevSelectedPosition, value];
        setRoles((prevRoles) => {
          const roleExists = prevRoles.some(role => role.role_id === ID);
          if (roleExists) {
            return prevRoles.map(role =>
              role.role_id === ID ? { ...role, org_array: [] } : role
            );
          } else {
            return [...prevRoles, { role_id: ID, org_array: [] }];
          }
        });
      } else {
        newArr = prevSelectedPosition.filter(pos => pos !== value);
        setRoles(prevRoles =>
          prevRoles.filter(role => role.role_id !== ID)
        );
      }
      return newArr;
    });
  };



  const handleCheckboxChange = (event) => {
    const { checked, value, dataset, id } = event.target;
    const parent = Number(dataset.parent);
    setRoles(prevRoles =>
      prevRoles.map(role => {
        if (role.role_id === parent) {
          let newOrgArr;
          if (checked) {
            if (parent === 3 || parent === 4) {
              newOrgArr = [+(id)];
            } else {
              newOrgArr = [...(role.org_array || []), +(id)];
            }
          } else {
            newOrgArr = (role.org_array || []).filter(org => org !== +(id));
          }
          return {
            ...role,
            org_array: newOrgArr
          };
        }
        return role;
      })
    );
  };

  const isChecked = (parentId, orgId) => {
    const role = roles.find(role => role.role_id === parentId);
    return role ? role.org_array.includes(orgId) : false;
  };

  return (
    <>
      <div className="employee-form">
        <h2>{isUpdate ? "Update User" : "Add User"}</h2>

        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1} columnSpacing={4} className="w-100">
            <Grid item md={6} xs={12}>
              <label>Enter First Name</label>
              <input
                name="first_name"
                type="text"
                placeholder="Enter First Name"
                className="form-control"
                maxLength={100}
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <label>Enter Last Name</label>
              <input
                name="last_name"
                type="text"
                placeholder="Enter Last Name"
                className="form-control"
                maxLength={100}
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <label>Enter Email</label>
              <input
                name="email_id"
                type="email"
                placeholder="Enter Email"
                className="form-control"
                value={formData.email_id}
                onKeyDown={(e) => isValidEmail(e.target.value)}
                onChange={handleInputChange}
              />
              {inValidEmail && <span className="text-danger">Invalid Email</span>}
            </Grid>
            <Grid item md={6} xs={6}>
              <label>Enter Hourly/Rate</label>
              <input
                name="hourly_rate"
                type="text"
                placeholder="Enter Hourly/Rate"
                className="form-control"
                value={formData.hourly_rate}
                maxLength={10}
                onKeyDown={(e) => handleNumberFormat(e)}
                onChange={handleInputChange}
              />
            </Grid>
            {radioArray.map((x, index) => (
              <React.Fragment key={index}>
                <Grid item md={4} xs={6}>
                  <input
                    id={x.id}
                    name="role_id"
                    type="checkbox"
                    value={x.value}
                    onChange={handleRadioChange}
                    data-child={x.value}
                    checked={selectedPosition.includes(x.value)}
                  />
                  <label htmlFor={x.value}>{x.name}</label>
                </Grid>
                {selectedPosition.includes(x.value) && (
                  <Grid item xs={12}>
                    <div
                      className="border"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {OrgName.map((org, orgIndex) => (
                        <Grid key={orgIndex} item md={4} sm={4} xs={6}>
                          <input
                            id={org.id}
                            name={org.name}
                            type="checkbox"
                            value={org.name}
                            data-parent={x.id}
                            // data-parent={roles.find(role => role.childOrg === selectedPosition)?.id}
                            // disabled={!selectedPosition || !roles.find(role => role.childOrg === selectedPosition)}
                            disabled={!selectedPosition}
                            onChange={handleCheckboxChange}
                            // checked={isChecked(roles.find(role => role.childOrg === selectedPosition)?.id, org.id)}
                            checked={isChecked(x.id, org.id)}
                          />
                          <label htmlFor={`org-${x.id}-${org.id}`}>{org.name}</label>
                        </Grid>
                      ))}
                    </div>
                  </Grid>
                )}
              </React.Fragment>
            ))}

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingButton type="submit" className="btn btn-primary my-10" loading={isLoading}
                loadingPosition="end" variant="contained">
                {isUpdate ? "Update" : "Submit"}
              </LoadingButton>
            </Grid>
          </Grid>
          <SnackbarMsg
            apiMsg={apiMsg}
            open={open}
            color={snackbarColor}
          ></SnackbarMsg>
        </form>
      </div>
    </>

  );
};

export default AddEmployee;
