import React from 'react'
import { SnackbarContent } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

const SnackbarMsg = (props) => {
    const {apiMsg,open,color}=props;
    // console.log(color,'===')
    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={500}
                message={apiMsg}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <SnackbarContent
                     style={{ backgroundColor: color }}
                    message={apiMsg}
                />
            </Snackbar>
        </div>
    )
}

export default SnackbarMsg