import React, { useEffect, useState } from "react";
import { fetchApi } from "../../services/api.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const ProjectPercent = ({ addProject, setaddProject }) => {
  const [percent, setPercent] = useState([]);
  const [splitPer, setSplitPer] = useState(0);

  useEffect(() => {
    console.log("uc call");
    calPer();
  }, [percent]);

  const addPercent = (e) => {
    e.preventDefault();
    const newPercent = {
      company_abbr: null,
      project_per: null,
    };
    setPercent([...percent, newPercent]);
  };

  const calPer = () => {
    let total = 0;
    for (let i = 0; i < percent.length; i++) {
      console.log(percent[i].project_per);
      total += Number(percent[i].project_per);
    }
    setSplitPer(total);
  };
  const handleChange = (idx, event) => {
    const { name, value } = event.target;
    console.log("xxyyzzz", value);
    const updatedPercent = percent.map((per, index) =>
      idx === index ? { ...per, [name]: value } : per
    );

    setPercent(updatedPercent);
    setaddProject({ ...addProject, project_percent: updatedPercent });
  };



  const [orgs, setOrgs] = useState([]);
  const getOrg = async () => {
    const urlOrg = await fetchApi("/api/orgs");
    const orglist = await urlOrg.json();
    console.log(orglist);
    setOrgs(orglist.palyload);
  };

  useEffect(() => {
    getOrg();
  }, []);

  return (
    <div className="project-per">
      <div className="p-flex" style={{ gap: "60px", margin: "0px" }}>
        <p>Company</p>
        <p>Split %</p>
      </div>
      <div>
        {percent.map((element, index) => (
          <div key={index} className="p-flex">
            <span>
              <select
                type="text"
                name="company_abbr"
                value={element.company_abbr}
                onChange={(event) => handleChange(index, event)}
              >
                <option>Company</option>
                {orgs.map((org, idx) => {
                  return (
                    <option value={org.abbr} type="text" key={idx}>
                      {org.abbr}
                    </option>
                  );
                })}
              </select>
            </span>
            <span>
              <input
                type="number"
                name="project_per"
                className="split-input"
                value={element.project_per}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
          </div>
        ))}
      </div>
      <div>
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={addPercent}
          style={{
            color: "#0056b3",
            height: "15px",
            // margin: "15px",
            display: "flex",
            justifyContent: "flex-start",
            cursor: "pointer"
          }}
        />
      </div>
      <div>TOTAL : {splitPer}</div>
    </div>
  );
};

export default ProjectPercent;
