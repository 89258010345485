import React, { useEffect, useState } from "react";
import "./SendtoxeroPayInv.css";
import { getSplitbillData } from "../../services/Currency.service";
import { fetchApi } from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SendtoxeroPayInv = () => {
  const [splitbill, setSplitbill] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rerender, setRerender] = useState(false);

  const fetchsplitBill = async () => {
    // let bills = await getSplitbillData();
    // setSplitbill(bills);

    setIsLoading(true);
    let bills = await getSplitbillData();
    setSplitbill(bills);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchsplitBill();
  }, []);

  const navigate = useNavigate();

  const getOrgName = (event) => {
    setOrgName(event.target.value);
    console.log(event.target.value);
  };

  // connect to xero
  const handleConnectToXero = async () => {
    let response = await fetchApi(`/api/xero/connect`);
    response = await response.json();
    window.open(response.consentUrl, "_blank");
  };

  const handleCreateInvoice = async () => {
    setIsLoading(true);
    const orgInvoiceArray = [];
    splitbill.map((elem, idx) => {
      return elem.org_splits.map((e, i) => {
        if (orgName === e.organisation.name && !e.payable_status) {
          const payInvObj = {
            p_tid: e.organisation.xero_tenant_id,
            p_from: e.organisation.id,
            p_to: elem.bill.organisation.id,
            p_description: e.description,
            p_org_amt: e.payOrganizationAmount,
            p_inv_no: e.split_invoice_no + "P",
            p_due_date: elem.bill.due_date,
            p_issue_date: elem.bill.issue_date,
            p_reference: elem.bill.reference,
            split_inv_id: elem.id,
            sbo_id: e.id,
            bill_id: elem.bill.id,
            p_gl_code: elem.gl_code_pay,
          };

          orgInvoiceArray.push(payInvObj);
        }
      });
    });

    const payInv = { p_invs: orgInvoiceArray };

    console.log("zzzzzzzzz", payInv);

    let response = await fetchApi(`/api/xero/mpinvoices`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payInv),
    });
    response = await response.json();
    console.log(response);
    setIsLoading(false);
    if (response.success) {
      alert("payable invoice sent to xero");
    } else if (response.ind === "UA") {
      alert("connect to xero and try again");
    }
    fetchsplitBill();
    navigate("/home/readytosendpayinvtoxero");
    setRerender(!rerender);
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="filter-by-org">
            <div>
              <Button sx={{ fontSize: "0.6rem" }} variant="contained" size="small" onClick={handleConnectToXero}>
                Connect to Xero
              </Button>
            </div>
            <div>
              <select onChange={getOrgName} value={orgName}>
                <option>Filter By Organisation</option>
                <option value="Traffic NZ">Traffic NZ</option>
                <option value="Refresh NZ">Refresh NZ</option>
                <option value="Refresh US">Refresh US</option>
                <option value="Refresh UK">Refresh UK</option>
                <option value="Refresh AU">Refresh AU</option>
                <option value="Refresh International">
                  Refresh International
                </option>
                <option value="Zones NZ">Zones NZ</option>
                <option value="Zones AU">Zones AU</option>
                <option value="Zones International">Zones International</option>
                <option value="Oncore NZ">Oncore NZ</option>
                <option value="Oncore International">
                  Oncore International
                </option>
              </select>
            </div>
          </div>
          <TableContainer className="center-table-div" sx={{ '& .MuiTableCell-root': { fontSize: '10px', fontWeight: "600" } }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Invoice No</TableCell>
                  <TableCell align="left">From</TableCell>
                  <TableCell align="left">To</TableCell>
                  <TableCell align="left">Currency</TableCell>
                  <TableCell align="left">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {splitbill.map((elem, idx) => {
                  return elem.bill.status === "AP"
                    ? elem.org_splits.map((el, i) => {
                      return !el.payable_status &&
                        orgName === el.organisation.name ? (
                        <TableRow>
                          <TableCell align="left">{el.split_invoice_no}P</TableCell>
                          <TableCell align="left">{el.organisation.name}</TableCell>
                          <TableCell align="left">{elem.bill.organisation.name}</TableCell>
                          <TableCell align="left">{el.organisation.currency}</TableCell>
                          <TableCell align="left">{el.payOrganizationAmount}</TableCell>
                        </TableRow>
                      ) : !el.payable_status && orgName === "" ? (
                        <TableRow>
                          <TableCell align="left" style={{ padding: "8px" }}>{el.split_invoice_no}P</TableCell>
                          <TableCell align="left">{el.organisation.name}</TableCell>
                          <TableCell align="left">{elem.bill.organisation.name}</TableCell>
                          <TableCell align="left">{el.organisation.currency}</TableCell>
                          <TableCell align="left">{el.payOrganizationAmount}</TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      );
                    })
                    : null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default SendtoxeroPayInv;
