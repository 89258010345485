import React, { useContext, useEffect, useState } from "react";
import "./Events.css";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SegmentRoundedIcon from "@mui/icons-material/SegmentRounded";
import GlobalContext from "../TimesheetContext/GlobalContext";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import dayjs from "dayjs";
import {
  DeleteTask,
  addTask,
  getProject,
  getTask,
  updateTask,
} from "../../services/Currency.service";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import { green } from "@mui/material/colors";
import { faL } from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "@mui/lab/LoadingButton";

const labelsClasses = ["indigo", "gray", "green", "blue", "red", "purple"];

export const Events = ({ user }) => {
  // console.log("user event", user)
  const [color, setColor] = useState(labelsClasses);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const {
    setShowEvent,
    daySelected,
    selectedEvent,
    setSelectedEvent,
    openTimeSheetModal,
    setOpenTimeSheetModal,
    setFetchData, fetchData
  } = useContext(GlobalContext);

  // console.log("selectedEvent", selectedEvent)

  const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );
  const [Project, setProject] = useState(
    selectedEvent ? selectedEvent.Project : ""
  );

  const [start_time, setStartTime] = useState(
    selectedEvent ? selectedEvent.start_time : ""
  );

  const [end_time, setEndTime] = useState(
    selectedEvent ? selectedEvent.end_time : ""
  );

  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );

  const [activeProject, setActiveProject] = useState([]);

  const [hour, setHour] = useState(selectedEvent ? selectedEvent?.hour : "");

  const handleClose = () => {
    setOpenTimeSheetModal(false);
  };

  async function handleSubmit(e) {
    setIsSubmit(true)
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      Project,
      start_time,
      end_time,
      label: selectedLabel,
      day: daySelected,
      user_id: user?.id,
      hour,
    };

    // console.log("calendarEvent", calendarEvent);
    if (selectedEvent) {
      // console.log("selected Event", selectedEvent.id);
      // console.log("xxxxxxx", daySelected);
      const UpdateResult = await updateTask(calendarEvent, selectedEvent?.id);
      // console.log("UpdateResult", UpdateResult)
      if (UpdateResult) {
        setApiMsg(UpdateResult?.message);
        setOpenSnackbar(true)
        setFetchData(!fetchData)
        // console.log("1")
      }
    } else {
      const result = await addTask(calendarEvent);
      if (result) {
        setApiMsg(result?.message);
        setOpenSnackbar(!fetchData)
        setFetchData(!fetchData)
      }
    }
    setIsSubmit(false)
    setShowEvent(false);
    setTimeout(() => { setOpenSnackbar(false) }, 1500);
    setTimeout(() => { setOpenTimeSheetModal(false) }, 2000);
  }

  const handleDeleteTask = async () => {
    await DeleteTask(selectedEvent.id);
    alert("Task Deleted Successfully");
    setShowEvent(false);
    setSelectedEvent(null);
  };

  // const showTimeStampIcon = () => {
  //   if (dayjs(daySelected).isBefore(dayjs().format("MM-DD-YYYY"))) {
  //     return true;
  //   }
  //   return false;
  // };

  const getActiveProjects = async () => {
    const activePro = await getProject(user?.id, user?.role);
    // console.log("Projject", activePro);
    if (user?.role == 3) {
      setActiveProject(activePro);
    } else {
      setActiveProject(activePro);
    }

    // getProject()
    //   .then((d1) => {
    //     return d1;
    //     // console.log(d1);
    //   })
    //   .then((d2) => {
    //     setActiveProject(d2);
    //     return activeProject;
    //   })
    //   .then((d3) => {
    //     console.log(d3);
    //   });
  };

  useEffect(() => {
    getActiveProjects();
  }, [openSnackbar, fetchData]);

  return (
    <>
      <Dialog onClose={handleClose} open={openTimeSheetModal} >
        <DialogContent className="events-container">
          <form className="events-form">
            <DialogTitle>
              <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
            </DialogTitle>
            <div className="addEvent-field-div">
              <select
                value={Project}
                style={{
                  height: "2rem",
                  border: "1px solid black",
                  borderRadius: "3px",
                }}
                required
                onChange={(e) => setProject(e.target.value)}
                className="select-tag"
              >
                <option value="Select Project">Select Project</option>
                {activeProject?.map((ele, i) => {
                  // console.log("evelem", ele);
                  // console.log("users", user);
                  return (
                    <option
                      value={
                        user?.role == 3
                          ? ele.active_project.project_title
                          : ele.project_title
                      }
                      type="text"
                      key={i}
                    >
                      {user?.role == 3
                        ? ele.active_project.project_title
                        : ele.project_title}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="addEvent-field-div">
              <input
                type="text"
                name="title"
                placeholder="Add Task title"
                value={title}
                required
                className="input-tag"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="date-time">
              <div>
                <CalendarMonthIcon />
              </div>
              <div><span>{dayjs(daySelected).format("dddd, MMMM DD")}</span></div>
            </div>
            <div className="segment-input addEvent-field-div">
              <span>
                <DescriptionIcon />
              </span>
              <input
                type="text"
                name="description"
                placeholder="Add a description"
                value={description}
                required
                className="input-tag"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="bookmark-color">
              <span>
                <BookmarkIcon />
              </span>
              <div className="horizontal-container">
                {color.map((lblClass, i) => (
                  <span
                    key={i}
                    onClick={() => setSelectedLabel(lblClass)}
                    id="Color-coding"
                    className={`bg-${lblClass}-500`}
                  >
                    {selectedLabel === lblClass && (
                      <span className="material-icons-check">
                        <CheckRoundedIcon />
                      </span>
                    )}
                  </span>
                ))}
              </div>
            </div>
            <div className="Time">
              <p className="schedule-icon">
                <WatchLaterIcon />
              </p>
              <input
                placeholder="Hour"
                className="timingInput"
                name="Hour"
                value={hour}
                required
                type="number"
                onChange={(e) => setHour(e.target.value)}
              />
            </div>
            <DialogActions>
              <LoadingButton
                type="submit" loading={isSubmit}
                variant="contained"
                size="small" sx={{ fontSize: "0.8rem" }}
                onClick={handleSubmit}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={"green"} />
    </>
  );
};



{/* <header className="events-header">
              <span className="events-header-icon">
                <DragHandleIcon />
              </span>

              <div>
                {selectedEvent && (
                  <span
                    onClick={handleDeleteTask}
                    className="events-header-button-icon"
                  >
                    <DeleteRoundedIcon />
                  </span>
                )}
                <button className="events-header-button">
                  <span className="events-header-button-icon">
                    <CloseIcon onClick={handleClose} />
                  </span>
                </button>
              </div>
            </header> */}


{/* {showTimeStampIcon()  ? (
            <div className="Time">
              <p className="schedule-icon">
                <ScheduleIcon />
              </p>
              <input
                placeholder="Start Time"
                className="timingInput"
                name="start_time"
                value={start_time}
                required
                type="text"
                onFocus={(e) => {
                  e.target.type = "time";
                }}
                onBlur={(e) => {
                  e.target.type = "text";
                }}
                onChange={(e) => setStartTime(e.target.value)}
              />
              <input
                placeholder="End Time"
                className="timingInput"
                name="end_time"
                value={end_time}
                required
                type="text"
                onFocus={(e) => {
                  e.target.type = "time";
                }}
                onBlur={(e) => {
                  e.target.type = "text";
                }}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
          ) : null} */}