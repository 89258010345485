import React from "react";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import Content from "./Content/Content";
// import "../App.css"

function Home({ user }) {
  return (
    <div>
      <Navbar user={user} />
      <div style={{ display: "flex",/*  padding:"10px" */ backgroundColor: "white" }} className="Main-container">
        <Sidebar user={user} />
      </div>
    </div>
  );
}

export default Home;
