import React from "react";
import "./Sidebar.css";
import { NavLink, Outlet } from "react-router-dom";
import { Paper } from "@mui/material";
import { Box } from "@mui/system";

const Sidebar = ({ user }) => {
  return (
    <>
      <Box className="sidebar-container" /* component={Paper} */>
        <div className="sidebar-item">
          <div>
            {(user.role === 2 || user.role === 3 || user.role == 4) && user.isSuperAdmin &&
              <NavLink
                to="/home/superadmin"
                style={{ textDecoration: "none" }}
                className={({ isActive }) => (isActive ? "link-active" : "link")}
              >
                <h3 className="sidebar-link">SUPERADMIN</h3>
              </NavLink>}
          </div>
          {user.role === 2 &&
            <NavLink
              to="/home/content"
              style={{ textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              <h3 className="sidebar-link">OUTFLOW</h3>
            </NavLink>}

          {/* <div> {user.role === 2 && <h3>PO'S</h3>}</div> */}
          <div>
            <NavLink
              to="/home/timesheet"
              style={{ textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              <h3 className="sidebar-link" style={{ textDecoration: "none" }}>
                TIMESHEET
              </h3>
            </NavLink>
          </div>
          <div>
            {user.role === 4 || user.role === 2 ? (
              <NavLink
                to="/home/activeprojects"
                style={{ textDecoration: "none" }}
                className={({ isActive }) => (isActive ? "link-active" : "link")}
              >
                <h3 className="sidebar-link" style={{ textDecoration: "none" }}>
                  PROJECTS
                </h3>
              </NavLink>
            ) : null}
          </div>
          {/* <div>{user.role === 2 && <h3>EXPENSES</h3>}</div> */}
          <div>
            {user.role === 2 &&
              <NavLink
                to="/home/currency"
                style={{ textDecoration: "none" }}
                className={({ isActive }) => (isActive ? "link-active" : "link")}
              >
                <h3 className="sidebar-link" style={{ textDecoration: "none" }}>
                  CURRENCY
                </h3>
              </NavLink>}

          </div>
          <div>
            {
              user.role === 2 && <NavLink
                to="/home/bi-reports"
                style={{ textDecoration: "none" }}
                className={({ isActive }) => (isActive ? "link-active" : "link")}
              >
                <h3 className="sidebar-link" style={{ textDecoration: "none" }}>
                  BI Reports
                </h3>
              </NavLink>
            }
          </div>
        </div>
      </Box>
      <Outlet></Outlet>
    </>
  );
};

export default Sidebar;
